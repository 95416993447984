import React from "react"
import Layout from "../components/layout"
import HeroStatic from "../components/heroStatic"
import Content from "../components/content"
const Blog = () => {
  return (
    <Layout>
      <HeroStatic title="blog" />
      <Content>
        <h2> All the blog in the world</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita,
          nihil, a sunt neque aperiam numquam magnam quos molestiae maiores
          nulla temporibus, iste ipsam amet tenetur voluptates fuga aut nesciunt
          corrupti deserunt odio. Amet quibusdam, quo atque facere aliquid
          dolores officia accusantium totam tempora. Ipsam beatae quia
          reiciendis impedit, deserunt error libero earum facere sunt laboriosam
          consectetur sed praesentium aperiam commodi illum? Incidunt eveniet,
          iste voluptatum, totam omnis id fuga error libero voluptates cum
          possimus. Magnam voluptatibus facere harum porro asperiores
          exercitationem laborum ratione quia sint dolorum, sequi eius quo quis
          eaque totam similique modi aut reiciendis, adipisci iusto? Perferendis
          fugit nemo assumenda quisquam odit excepturi tenetur ad iure deleniti
          nisi, mollitia dolorem accusantium beatae velit quo nostrum placeat
          sequi quod praesentium architecto reprehenderit. Sint nisi quos
          nesciunt qui totam, reiciendis quibusdam odit a eum enim minima neque
          et soluta quae voluptatibus odio iure blanditiis amet dicta
          perspiciatis veritatis atque. Adipisci sapiente reiciendis distinctio
          eligendi, quae veritatis? Asperiores, expedita debitis sint quod quia
          quam praesentium ducimus animi inventore velit? Rem repellendus
          mollitia soluta impedit sint qui aperiam accusamus velit magni neque
          quisquam ut aliquid odit, et doloribus dolores voluptatem fugiat, sed
          placeat in atque id quam culpa? Natus aliquam deserunt illum
        </p>
      </Content>
    </Layout>
  )
}
export default Blog
